// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAsXrWIc3lGHiXusTMPVC4ZIQTxzvLlr0s",
  authDomain: "typist-1a4b5.firebaseapp.com",
  databaseURL: "https://typist-1a4b5-default-rtdb.firebaseio.com",
  projectId: "typist-1a4b5",
  storageBucket: "typist-1a4b5.appspot.com",
  messagingSenderId: "182851217960",
  appId: "1:182851217960:web:756baccf36b20d1e1fe31d"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);