import React, { useState, useEffect } from 'react';
import axios from 'axios'
import data from '../constants/merriam'
import { getDatabase, ref, push, onValue } from 'firebase/database';

function App(props) {

  const db = getDatabase()
  const wordsdata = Object.keys(data);
  const timer = 15;
  const [definitions, setDefinitions] = useState([])
  const [wordsUsed, setWordsUsed] = useState([])
  const [scores, setScores] = useState([])
  const [gameType, setGameType] = useState("");
  const [words, setWords] = useState([])
  const [word, setWord] = useState('')
  const [currentWord, setCurrentWord] = useState(0)
  const [initials, setInitials] = useState('')
  const [loading, setLoading] = useState(true)
  const [score, setScore] = useState(0)
  const [seconds, setSeconds] = useState(timer)
  const [gameStarted, setGameStarted] = useState(false)
  const [gameOver, setGameOver] = useState(false)
  const [highscores, setHighscore] = useState([])
  const [darkMode, setDarkMode] = useState(false)
  function shuffle(array) {
    let currentIndex = array.length,
      randomIndex

    // While there remain elements to shuffle.
    while (currentIndex != 0) {
      // Pick a remaining element.
      randomIndex = Math.floor(Math.random() * currentIndex)
      currentIndex--

        // And swap it with the current element.
        ;[array[currentIndex], array[randomIndex]] = [
          array[randomIndex],
          array[currentIndex]
        ]
    }

    return array
  }

  const fetchDefinition = async word => {
    word = 'kale'
    try {
      const response = axios.get(
        `https://www.dictionaryapi.com/api/v3/references/collegiate/json/${word}?key=ccf013a9-9707-4209-b097-bbb7c663dc90`
      )
      console.log(response)
      const definition = response.data[0]?.shortdef?.[0]
    } catch (error) {
      console.log(error)
      return null
    }
  }

  useEffect(() => {
    if (wordsUsed) {
    const fetchData = async () => {
      let toAdd = [];
      for (let i = 0; i < wordsUsed.length; i++) {
        try {
          const response = await axios.get(
            `https://www.dictionaryapi.com/api/v3/references/collegiate/json/${wordsUsed[i]}?key=ccf013a9-9707-4209-b097-bbb7c663dc90`
          );
          const definition = response.data[0]?.shortdef?.[0];
          toAdd.push(definition);
        } catch (error) {
          console.log(error);
          toAdd.push(null); // Handle the error and push null to the array
        }
      }
      setDefinitions(toAdd.filter(Boolean)); // Remove null values from the array
    };
  
    fetchData();
  }
  }, [wordsUsed]);

  useEffect(() => {
    shuffle(wordsdata)
    setWords(wordsdata)
    setLoading(false)
  }, [])

  const toggle = () => {
    setDarkMode(!darkMode)
  }

  function restart() {
    setLoading(true)
    // add to firebase
    const drawingsRef = ref(db, 'typist/');
    // Your data
    const newData = {
      score: score,
      createdAt: Date.now(),
      gameType: gameType,
      initials: initials ? initials : "AAA",
    };

    push(drawingsRef, newData).then((newRef) => {
      const insertedId = newRef.key;
    });
    setGameStarted(false);
    setGameOver(false)
    setWord('')
    setCurrentWord(0)
    shuffle(wordsdata)
    setWords(wordsdata)
    setInitials('')
    setScore(0)
    setLoading(false)
  }

  useEffect(() => {
    setWordsUsed(old => [...old, words[currentWord]])
  }, [currentWord])

  useEffect(() => {
    if (
      currentWord < words.length &&
      word.toLowerCase() === words[currentWord].toLowerCase()
    ) {
      setCurrentWord(old => old + 1)
      setScore(old => old + word.length)
      setScores(old => [...old, score])
      setWord('')
    }
  }, [word, currentWord, words])

  useEffect(() => {
    if (seconds <= 0) {
      setGameOver(true)
      setGameStarted(false)
      setSeconds(timer)
    }
  }, [seconds])

  useEffect(() => {
    const starCountRef = ref(db, 'typist');
    onValue(starCountRef, (snapshot) => {
      const data = snapshot.val();
      const dataArray = data ? Object.values(data) : [];

      // Sort the array by score in descending order
      const sortedArray = dataArray.sort((a, b) => b.score - a.score);
      const sliced = sortedArray
      setHighscore(sliced)
      console.log(sliced)
    });
  }, [gameOver])

  useEffect(() => {
    if (seconds > 0 && gameStarted) {
      const interval = setInterval(() => {
        setSeconds(prevSeconds => prevSeconds - 1)
      }, 1000)

      return () => clearInterval(interval)
    }
  }, [seconds, gameStarted])

  const startSelect = (time, type) => {
    setGameType(type)
    setSeconds(time)
    setWordsUsed('')
    setDefinitions('')
    setGameStarted(true)
  }

  function capMe(e) {
    setInitials(e.target.value.toUpperCase())
  }

  if (loading) {
    return <p>Loading</p>
  }
  return (

    <>
    {gameStarted ? (
        <>
          <p className='timer'>{seconds}</p>
          <p className='word'>
            {words[currentWord].split('').map((char, index) => (
              <span
                key={index}
                style={{
                  color:
                    word.split('')[index] === char
                      ? 'green'
                      : word.split('')[index] !== char && word.split('')[index]
                        ? 'red'
                        : ''
                }}
              >
                {char}
              </span>
            ))}
          </p>
          <input
            spellCheck='false'
            autoFocus
            type='text'
            value={word}
            placeholder='Enter word'
            onChange={e => setWord(e.target.value)}
          />

          <p className='score'>Score: {score}</p>
        </>
      ) : gameOver ? (
        <>
          <p className='results'>You scored {score} points</p>
          <p>Enter intitials</p>
          <input type="text" value={initials} onChange={(e) => capMe(e)} maxLength={3} placeholder="AAA" />
          <button style={{ marginRight: "1rem"}} onClick={() => restart()}>Add initials</button>
          <button onClick={() => restart()}>Home</button>
          
          <h2 style={{marginTop: "2rem", marginBottom: "2rem"}}>Words used</h2>
          <ul className="definitions">
            {
              wordsUsed && 
              wordsUsed.map((item, index) => {
                return (
                  <li key={index}>
                    <h4>{item}</h4>
                    <p>{definitions[index] ? definitions[index] : "Can't find definition"}.</p>
                  </li>
                )
              })
            }
          </ul>
        </>
      ) : (
        <>
        
          <p>You have a fixed amount of time to type as many words as possible. Once you've successfully typed the word, it will automatically go to the next word with no need to press enter. Have fun!</p>
           <div style={{ display: "flex", gap: "2rem", marginTop: "2rem", width: "100%", justifyContent: "space-between"}}>
           
            <div className="card">
              <button style={{marginBottom: "2rem"}} onClick={() => startSelect(30, "medium")}>Start :30</button>
            
              <h3>:30 scores</h3>
              <ul className="highscore">
                {highscores.map((item, index) => {
                  return item.gameType === "medium" && 
                    (
                      <li key={index}>
                        <span>{item.initials.toUpperCase()}: {item.score}</span>
                      </li>
                    )
                })}
              </ul>
            </div>
            <div className="card">

             <button style={{ marginBottom: "2rem"}} onClick={() => startSelect(15, "hard")}>Start :15</button>
    
              <h3>:15 scores</h3>
              <ul className="highscore">
                {highscores.map((item, index) => {
                  return item.gameType === "hard" && 
                    (
                      <li key={index}>
                        <span>{item.initials.toUpperCase()}: {item.score}</span>
                      </li>
                    )
                })}
              </ul>
            </div>
         
          </div> 
            <p style={{marginTop: "2rem"}}>Words and definitions are from <a href="https://dictionaryapi.com">Mirriam Webster</a>.</p>

        </>
      )}
    </>
  );
}

export default App;
