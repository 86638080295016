import React, { useState, useEffect } from 'react';
import './App.css'
import axios from 'axios'
import data from './constants/merriam'
import { getDatabase, ref, push, onValue } from 'firebase/database';
import './constants/firebase'
import { getAuth, signInAnonymously } from "firebase/auth";

import Home from './pages/Home'
import Navbar from './components/Navbar'
function App() {
  const auth = getAuth();
  const db = getDatabase()
  const wordsdata = Object.keys(data);
  const timer = 15
  const [definition, setDefinition] = useState('')

  const [wordsUsed, setWordsUsed] = useState([
    'kale',
    'glass',
    'mug',
    'sunglasses'
  ])
  const [scores, setScores] = useState([])
  const [user, setUser] = useState(false)
  const [gameType, setGameType] = useState("");
  const [words, setWords] = useState([])
  const [word, setWord] = useState('')
  const [currentWord, setCurrentWord] = useState(0)
  const [initials, setInitials] = useState('')
  const [loading, setLoading] = useState(true)
  const [score, setScore] = useState(0)
  const [seconds, setSeconds] = useState(timer)
  const [gameStarted, setGameStarted] = useState(false)
  const [gameOver, setGameOver] = useState(false)
  const [highscores, setHighscore] = useState([])

  const [darkMode, setDarkMode] = useState(true)
  useEffect(() => {
    signInAnonymously(auth)
    .then((userCredentials) => {
      // Signed in..
      setUser(userCredentials.user)
    })
    .catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
      // ...
      alert("Anonymous login failed. Please try again.")
      return;
    });
  }, [])

  const toggle = () => {
    setDarkMode(!darkMode)
  }
  function shuffle(array) {
    let currentIndex = array.length,
      randomIndex

    // While there remain elements to shuffle.
    while (currentIndex != 0) {
      // Pick a remaining element.
      randomIndex = Math.floor(Math.random() * currentIndex)
      currentIndex--

        // And swap it with the current element.
        ;[array[currentIndex], array[randomIndex]] = [
          array[randomIndex],
          array[currentIndex]
        ]
    }

    return array
  }

  const fetchDefinition = async word => {
    try {
      const response = await axios.get(
        `https://www.dictionaryapi.com/api/v3/references/collegiate/json/${word}?key=ccf013a9-9707-4209-b097-bbb7c663dc90`
      )
      const definition = response.data[0]?.shortdef?.[0]
      return definition
    } catch (error) {
      console.log(error)
      return null
    }
  }

  useEffect(() => {
    shuffle(wordsdata)
    setWords(wordsdata)
    setLoading(false)
  }, [])

  function restart() {
    setLoading(true)
    // add to firebase
    const drawingsRef = ref(db, 'typist/');
    // Your data
    const newData = {
      score: score,
      createdAt: Date.now(),
      gameType: gameType,
      initials: initials ? initials : "AAA",
    };

    push(drawingsRef, newData).then((newRef) => {
      const insertedId = newRef.key;
    });
    setGameStarted(false);
    setGameOver(false)
    setWord('')
    setCurrentWord(0)
    shuffle(wordsdata)
    setWords(wordsdata)
    setInitials('')
    setScore(0)
    setLoading(false)
  }

  useEffect(() => {
    setWordsUsed(old => [...old, words[currentWord]])
  }, [currentWord])

  useEffect(() => {
    if (
      currentWord < words.length &&
      word.toLowerCase() === words[currentWord].toLowerCase()
    ) {
      setCurrentWord(old => old + 1)
      setScore(old => old + word.length)
      setScores(old => [...old, score])
      setWord('')
    }
  }, [word, currentWord, words])

  useEffect(() => {
    if (seconds <= 0) {
      setGameOver(true)
      setGameStarted(false)
      setSeconds(timer)
    }
  }, [seconds])

  useEffect(() => {
    const starCountRef = ref(db, 'typist');
    onValue(starCountRef, (snapshot) => {
      const data = snapshot.val();
      const dataArray = data ? Object.values(data) : [];

      // Sort the array by score in descending order
      const sortedArray = dataArray.sort((a, b) => b.score - a.score);
      const sliced = sortedArray
      setHighscore(sliced)
      console.log(sliced)
    });
  }, [gameOver])

  useEffect(() => {
    if (seconds > 0 && gameStarted) {
      const interval = setInterval(() => {
        setSeconds(prevSeconds => prevSeconds - 1)
      }, 1000)

      return () => clearInterval(interval)
    }
  }, [seconds, gameStarted])

  const startSelect = (time, type) => {
    setGameType(type)
    setSeconds(time)
    setGameStarted(true)
  }

  if (loading) {
    return <p>Loading</p>
  }
  return (
    <div className={darkMode ? "dark" : "light"}>
    <div className="container">

    <header>
        <h1><a href="/">The Typist</a></h1>
        <p>A typing game by <a href="https://matthewgruman.com">Matthew Gruman</a></p>
        <button onClick={toggle}>{darkMode ? "Light mode" : "Dark mode"}</button>
      </header>
     <Home />
    </div>
    </div>
  );
}

export default App;
